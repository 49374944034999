import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from './Loading';
import { useQuery } from '@apollo/client';
import { SEARCH_ARTICLES } from './query';
import BreadCrumbs from './BreadCrumbs';

const TagArticles = ({ allCategories, stylesKb }) => {
	const { tagSearch } = useParams();
	const { data, loading } = useQuery(SEARCH_ARTICLES, { variables:
			//{ q: tagSearch, level: 'article', page: 'KB', domain: 'kb-1', isTag: true },
			{ q: tagSearch, level: 'article', page: 'KB', domain: document.location.hostname.split('.')[0], isTag: true },
		}
	);
	const [crumbs] = useState([{ title: 'Home', url: '/' }]);

	return (
		<div className="content tag-articles">
			<BreadCrumbs crumbs={crumbs} stylesKb={stylesKb} />
			{!loading ?
				<>
					{data.data && <h3>{data.data.length} article{data.data.length === 1 ? '' : 's'} tagged as {tagSearch}:</h3>}
					{data && data.data.map((article) =>
						<Link className="article" key={article.id} to={`/${allCategories.find(a => a.id === article.categoriesIds.split(',')[0]).slug}/${article.slug}`}>
							<div className="title-article" style={{color: stylesKb.brandColor}}>{article.title}</div>
							<div>{article.description}</div>
							<div className="published-category">Published in <span>{article.categoriesTitle}</span></div>
						</Link>
					)}
				</> : <Loading />
			}
		</div>
	);
};

export default TagArticles;