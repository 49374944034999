import React from 'react';
import './Header.scss';
import Search from './Search';
import { MEDIA_URL } from './Constants';

const Header = ({ stylesKb, kb, allCategories }) => {
	return (
		<header className="navbar"
		        style={{
		        	backgroundColor: stylesKb.bgColor,
			        backgroundImage: `url(${MEDIA_URL}${stylesKb.bgUrl})`,
			        color: stylesKb.fontColor
		        }}
		>
			{stylesKb.logoUrl &&
				<div className="logo-base">
					<img className={stylesKb.logoSize} src={MEDIA_URL + stylesKb.logoUrl} alt="logo"/>
				</div>
			}
			<h2 className="name">{kb.name}</h2>
			<h3>{stylesKb.header}</h3>
			<div>{stylesKb.subheader}</div>
			<Search placeholder={stylesKb.searchText} allCategories={allCategories} />
		</header>
	);
};

export default Header;
