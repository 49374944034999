import React, { useEffect, useState } from 'react';
import './Home.scss';
import './Articles.scss';
import { Link } from 'react-router-dom';
import { MEDIA_URL } from './Constants';

const Home = ({ kb, stylesKb }) => {
	const [featuredArticles, setFeaturedArticles] = useState([]);

	useEffect(() => {
		if (kb) {
			if (kb.publicSections.length) {
				const filterArticlesFeatured = [];
				kb.publicSections.forEach(item => {
					item.publicCategories.forEach(category => {
						filterArticlesFeatured.push(...category.publicArticles
							.map(b => ({ ...b, category: category, section: item })).filter(a => a.featured)
						);
					});
				});

				setFeaturedArticles(Array.from(new Set(filterArticlesFeatured.map(a => a.id)))
					.map(id => filterArticlesFeatured.find(a => a.id === id)));
			}
		}
	}, [kb]);

	return (
		<div className="content">
			<div className="section">
				{Boolean(featuredArticles.length) &&
					<div className="kb-item featured">
						<div className="image-logo">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
								<g>
									<g>
										<path d="M20.5,5C19.7,5,19,4.3,19,3.5S19.7,2,20.5,2S22,2.7,22,3.5S21.3,5,20.5,5z M20.5,3C20.2,3,20,3.2,20,3.5S20.2,4,20.5,4
											S21,3.8,21,3.5S20.8,3,20.5,3z"/>
									</g>
									<g>
										<path d="M16.5,5C15.7,5,15,4.3,15,3.5S15.7,2,16.5,2S18,2.7,18,3.5S17.3,5,16.5,5z M16.5,3C16.2,3,16,3.2,16,3.5S16.2,4,16.5,4
											S17,3.8,17,3.5S16.8,3,16.5,3z"/>
									</g>
									<g>
										<path d="M12.5,5C11.7,5,11,4.3,11,3.5S11.7,2,12.5,2S14,2.7,14,3.5S13.3,5,12.5,5z M12.5,3C12.2,3,12,3.2,12,3.5S12.2,4,12.5,4
											S13,3.8,13,3.5S12.8,3,12.5,3z"/>
									</g>
									<path d="M22.5,0h-21C0.7,0,0,0.7,0,1.5v17C0,19.3,0.7,20,1.5,20H10v3.5c0,0.3,0.2,0.5,0.5,0.5h2c0.7,0,1.2-0.4,1.4-1h8.6
										c0.8,0,1.5-0.7,1.5-1.5v-20C24,0.7,23.3,0,22.5,0z M1.5,1h21C22.8,1,23,1.2,23,1.5V6H1V1.5C1,1.2,1.2,1,1.5,1z M13,22.5
										c0,0.3-0.2,0.5-0.5,0.5H11v-9h1.5c0.3,0,0.5,0.2,0.5,0.5V22.5z M23,21.5c0,0.3-0.2,0.5-0.5,0.5H14v-7h1.5c0.8,0,1.5-0.7,1.5-1.5v-2
										c0-0.3,0.2-0.5,0.5-0.5h1c0.3,0,0.5,0.2,0.5,0.5v2c0,0.8,0.7,1.5,1.5,1.5h2c0.3,0,0.5,0.2,0.5,0.5V21.5z M16.1,11H3.5
										C3.2,11,3,10.8,3,10.5v-1C3,9.2,3.2,9,3.5,9h17C20.8,9,21,9.2,21,9.5v1c0,0.3-0.2,0.5-0.5,0.5h-0.6c-0.2-0.6-0.8-1-1.4-1h-1
										C16.8,10,16.3,10.4,16.1,11z M22.5,14h-2c-0.3,0-0.5-0.2-0.5-0.5V12h0.5c0.8,0,1.5-0.7,1.5-1.5v-1C22,8.7,21.3,8,20.5,8h-17
										C2.7,8,2,8.7,2,9.5v1C2,11.3,2.7,12,3.5,12H16v1.5c0,0.3-0.2,0.5-0.5,0.5h-1.6c-0.2-0.6-0.8-1-1.4-1h-2c-0.3,0-0.5,0.2-0.5,0.5V19
										H1.5C1.2,19,1,18.8,1,18.5V7h22v7.1C22.8,14,22.7,14,22.5,14z"/>
									<g>
										<path d="M4.5,14h-2C2.2,14,2,13.8,2,13.5S2.2,13,2.5,13h2C4.8,13,5,13.2,5,13.5S4.8,14,4.5,14z"/>
									</g>
									<g>
										<path d="M8.5,14h-2C6.2,14,6,13.8,6,13.5S6.2,13,6.5,13h2C8.8,13,9,13.2,9,13.5S8.8,14,8.5,14z"/>
									</g>
									<g>
										<path d="M4.5,16h-2C2.2,16,2,15.8,2,15.5S2.2,15,2.5,15h2C4.8,15,5,15.2,5,15.5S4.8,16,4.5,16z"/>
									</g>
									<g>
										<path d="M8.5,16h-2C6.2,16,6,15.8,6,15.5S6.2,15,6.5,15h2C8.8,15,9,15.2,9,15.5S8.8,16,8.5,16z"/>
									</g>
									<g>
										<path d="M4.5,18h-2C2.2,18,2,17.8,2,17.5S2.2,17,2.5,17h2C4.8,17,5,17.2,5,17.5S4.8,18,4.5,18z"/>
									</g>
									<g>
										<path d="M8.5,18h-2C6.2,18,6,17.8,6,17.5S6.2,17,6.5,17h2C8.8,17,9,17.2,9,17.5S8.8,18,8.5,18z"/>
									</g>
								</g>
								</svg>
						</div>
						<div className="section-title">Featured Articles</div>
						<div className="count-articles">{featuredArticles.length} articles</div>
						{featuredArticles.map(article =>
							<div className="article" style={{color: stylesKb.brandColor}} key={article.id}>
								<Link to={`${article.category.slug}/${article.slug}`}>
									{article.title}
								</Link>
							</div>
						)}
					</div>
				}
				{kb.publicSections.map(section =>
					<div key={section.id}>
						<div className="section-title">{section.title}</div>
						{section.publicCategories &&
							<div className="wrap-row">
								{section.publicCategories.map(category =>
									<Link className="kb-item"
									      to={`/section/${section.slug}/category/${category.slug}`}
									      key={category.id}
									>
										<div className="image-logo">
											{category.image ?
												<img src={`${MEDIA_URL}${category.image.datafile}`} alt="image"/> :
												<svg viewBox="0 0 490.667 490.667" xmlns="http://www.w3.org/2000/svg">
													<path d="M245.333,170.667c-1.088,0-2.133,0.085-3.968,0.299c-5.888,0.341-8.981,5.227-8.661,11.115s6.699,10.325,12.629,9.92
														c29.397,0,53.333,23.936,53.248,54.059c-0.021,0.213-0.192,1.856-0.192,2.091c-0.32,5.739,4.053,10.005,9.792,10.496
														c0.277,0.021,0.533,0.021,0.811,0.021c5.419,0,10.133-4.672,10.773-10.133c0.064-0.405,0.235-2.773,0.235-3.2
														C320,204.16,286.507,170.667,245.333,170.667z"/>
													<path d="M245.333,0C110.059,0,0,110.059,0,245.333s110.059,245.333,245.333,245.333s245.333-110.059,245.333-245.333
														S380.608,0,245.333,0z M245.333,469.333c-123.52,0-224-100.48-224-224c0-57.92,22.272-110.613,58.496-150.4l52.267,52.267
														c-15.808,14.08-25.429,34.155-25.429,55.467V288c0,41.173,33.493,74.667,74.667,74.667h128c10.773,0,21.269-2.773,30.997-7.232
														l55.403,55.403C355.947,447.061,303.253,469.333,245.333,469.333z M245.333,320c16.213,0,31.637-5.483,44.48-15.083
														l34.176,34.176c-4.757,1.365-9.664,2.24-14.656,2.24h-128C151.936,341.333,128,317.397,128,288v-85.333
														c0-15.893,7.061-30.315,19.072-40.491l38.677,38.677c-9.6,12.843-15.083,28.288-15.083,44.48
														C170.667,286.507,204.16,320,245.333,320z M192,245.333c0-10.517,3.221-20.629,8.939-29.291l73.707,73.707
														c-8.661,5.717-18.773,8.917-29.312,8.917C215.936,298.667,192,274.731,192,245.333z M410.816,395.755L94.912,79.851
														c39.808-36.224,92.48-58.517,150.421-58.517c123.52,0,224,100.48,224,224C469.333,303.275,447.04,355.947,410.816,395.755z"/>
													<path d="M309.312,128h-12.48c-4.416-12.416-16.277-21.333-30.187-21.333h-42.667c-14.507,0-26.795,9.728-30.699,22.997
														c-2.965,1.877-4.949,5.205-4.949,9.003c0,5.888,4.779,10.667,10.667,10.667h3.669c5.888,0,10.667-4.779,10.667-10.667
														S218.112,128,224,128h42.667c5.888,0,10.667,4.779,10.667,10.667s4.779,10.667,10.667,10.667h21.333
														c29.397,0,53.333,23.936,53.333,53.333V288c0,5.803-1.067,11.755-3.157,17.707c-1.941,5.568,0.981,11.648,6.528,13.611
														c1.173,0.405,2.368,0.597,3.541,0.597c4.395,0,8.512-2.752,10.027-7.125c2.901-8.256,4.373-16.576,4.373-24.789v-85.333
														C383.979,161.493,350.485,128,309.312,128z"/>
												</svg>
											}
										</div>
										<div className="title">
											{category.title ? category.title : <span>Default</span>}
										</div>
										<div className="description">{category.description}</div>
										{category.publicArticles.length > 0 &&
											<div className="count">
												{category.publicArticles.length} articles
											</div>
										}
									</Link>
								)}
							</div>
						}
					</div>
				)}
			</div>
		</div>
	);
};

export default Home;
