import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from './query';
import { useParams, Link } from 'react-router-dom';
import Loading from './Loading';
import { MEDIA_URL } from './Constants';

const Articles = ({ slug, stylesKb, articleSlug, isShowTitle = true }) => {
	const { categorySlug } = useParams();
	const [category, setCategory] = useState(null);

	const { data, loading } = useQuery(GET_CATEGORIES, {
		variables: { slug: (slug || categorySlug) },
	});

	useEffect(() => {
		if (data && data.data) {
			setCategory(data.data[0]);
		}
	}, [data]);

	return (
		<div className="articles">
			{!loading && category ?
				<>
					{isShowTitle &&
					    <>
						    {category.image && <div><img src={`${MEDIA_URL}${category.image.datafile}`} alt="logo" /></div>}
						    <div className="title">{category.title}</div>
						    <div className="description">{category.description}</div>
					    </>
					 }
					 <>
						 <div className="divider" />
						 {Boolean(category.publicArticles.length) ? category.publicArticles.map(article =>
							 <div className={`article ${article.slug === articleSlug ? 'active' : ''}`} key={article.id} style={{color: stylesKb.brandColor}}>
								 <Link to={`/${categorySlug}/${article.slug}`}>
									 {article.title}
								 </Link>
							 </div>
						    ) : <div className="no-articles">No articles</div>
						 }
					 </>
				</> : <Loading />
			}
		</div>
	);
};

export default Articles;
