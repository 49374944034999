import { gql } from '@apollo/client';

export const GET_KB = gql`
    query publicKb($domain: String!) {
        publicKb(domain: $domain) {
            id
			name
			domainName
			magicStyles
			publicSections {
				id
				title
				slug
				description
				publicCategories {
					id
					title
					slug
					description
					image {
						datafile
					}
					publicArticles {
						id
						title
						slug
						featured
					}
				}
			}
        }
    }
`;

export const GET_SECTIONS = gql`
    query sections($kbId: Int, $id: Int, $slug: String) {
		data: sections(kbId: $kbId, id: $id, slug: $slug) {
			id
			title
			description
			slug
			publicCategories {
				id
				title
				description
				slug
				publicArticles {
					id
					slug
					title
				}
			}
		}
	}
`;

export const GET_CATEGORIES = gql`
    query categories($sectionId: Int, $id: Int, $slug: String) {
		data: categories(sectionId: $sectionId, id: $id, slug: $slug) {
			id
			title
			description
			slug
			publicArticles {
				id
				slug
				title
			}
			image {
				id
				datafile
			}
		}
	}
`;

export const GET_PUBLIC_ARTICLES = gql`
	query publicArticles($categories_Id: Int, $categories_Slug: String) {
		data: publicArticles(categories_Id: $categories_Id, categories_Slug: $categories_Slug) {
			id
			categories {
				id
				slug
				title
				description
			}
			title
			slug
			description
		}
	}
`;

export const GET_PUBLIC_ARTICLE = gql`
	query publicArticle($id: Int, $slug: String, $kbId: Int) {
		data: publicArticle(id: $id, slug: $slug, kbId: $kbId) {
			id
			title
			slug
			description
			body
			createdAt
			updatedAt
			likes
			dislikes
			tags {
				id
				tag
			}
			categories {
				id
				title
				slug
				section {
					title
					slug
					publicCategories {
						id
						title
						slug
					}
				}
			}
		}
	}
`;

export const SEARCH_ARTICLES = gql`
	query search($q: String!, $level: String!, $page: String!, $domain: String, $isTag: Boolean, $published: Boolean) {
		data: search(q: $q, level: $level, page: $page, domain: $domain, isTag: $isTag, published: $published) {
			... on Article {
				id
				title
				description
				categoriesTitle
				authorName
				sectionTitle
				categoriesIds
				sectionId
				slug
			}
		}
	}
`;

export const LIKE_DISLIKE_ARTICLE = gql`
	mutation likeDislikeArticle($action: String, $articleId: Int) {
		data: likeDislikeArticle(action: $action, articleId: $articleId) {
			status
		}
	}
`;
