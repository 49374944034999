import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SECTIONS } from './query';
import { Outlet, useParams } from 'react-router-dom';

import './Section.scss';
import Loading from './Loading';
import Categories from './Categories';
import BreadCrumbs from './BreadCrumbs';

const Section = ({ stylesKb }) => {
	const { sectionSlug, categorySlug } = useParams();
	const { data, loading } = useQuery(GET_SECTIONS, { variables: { slug: sectionSlug }});

	const [crumbs, setCrumbs] = useState([{ title: 'Home', url: '/' }]);

	useEffect(() => {
		if (data && data.data) {
			const section = data.data[0];

			setCrumbs([
				{
					title: 'Home',
					url: '/',
				},
				{
					title: section.title,
					url: `/section/${sectionSlug}/category/${categorySlug}`,
				},
				{
					title: section.publicCategories.filter(d => d.slug === categorySlug)[0].title,
					url: `/section/${sectionSlug}/category/${categorySlug}`,
				},
			]);
		}
	}, [data, sectionSlug, categorySlug]);

	return (
		!loading ?
			<div className="content">
				<div className="section">
					<BreadCrumbs crumbs={crumbs} stylesKb={stylesKb} />
					<div className="section-main">
						<div className="category-aside">
							<Categories categories={data.data[0].publicCategories} stylesKb={stylesKb} />
						</div>
						<div className="content-aside">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		: <Loading />
	);
};

export default Section;
