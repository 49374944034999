import React, { useEffect, useState } from 'react';
import './App.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Section from './Section';
import Articles from './Articles';
import Article from './Article';
import PageError from './PageError';
import { useQuery } from '@apollo/client';
import { GET_KB } from './query';
import Header from './Header';
import TagArticles from './TagArticles';

export const App = () => {
    //const { data, loading } = useQuery(GET_KB, { variables: { domain: 'kb-1' }});
    const { data, loading } = useQuery(GET_KB, { variables: { domain: document.location.hostname.split('.')[0] }});
    const [stylesKb, setStylesKb] = useState({});
    const [allCategories, setAllCategories] = useState([]);

    const magicStyles = (data) => {
        let styles = {};
        try {
            styles = JSON.parse(data);
        } catch (e) {
            console.error(e);
        }

        styles.bgColor = styles.bgColor || '#2B78E4';
        styles.fontColor = styles.fontColor || '#FFFFFF';
        styles.brandColor = styles.brandColor || '#2B78E4';
        styles.header = styles.header || 'What are you looking for?';
        styles.subheader = styles.subheader || 'Get help straight from our team...';
        styles.searchText = styles.searchText || 'Search for answers...';

        setStylesKb(styles);
    };

    useEffect(() => {
        if (data && data.publicKb) {
            const allCategoriesArray = [];
            data.publicKb.publicSections.forEach(item => {
                item.publicCategories.forEach(category => {
                    allCategoriesArray.push({ slug: category.slug, id: category.id });
                });
            });

            document.title = data.publicKb.name;

            setAllCategories(allCategoriesArray);
            magicStyles(data.publicKb.magicStyles);
        }
    }, [data]);

    return (
        !loading &&
        <div className="main">
            {data && data.publicKb ?
                <Router>
                    <Header stylesKb={stylesKb} kb={data.publicKb} allCategories={allCategories} />
                    <Routes>
                        <Route exact path='/' element={<Home kb={data.publicKb} stylesKb={stylesKb} />} />
                        <Route exact path='section/:sectionSlug' element={<Section stylesKb={stylesKb} />}>
                            <Route path='category/:categorySlug' element={<Articles stylesKb={stylesKb} articles={[]} />} />
                        </Route>
                        <Route exact path='/:categorySlug/:articleSlug' element={<Article kbId={data.publicKb.id} stylesKb={stylesKb} />} />
                        <Route exact path='/tag/:tagSearch' element={<TagArticles allCategories={allCategories} stylesKb={stylesKb} />} />
                        <Route path="*" element={<PageError />}/>
                    </Routes>
                </Router>
                : <PageError isTitle={true}/>
            }
        </div>
    );
};
