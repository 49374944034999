import React from 'react';

const PageError = ({ isTitle }) => {
	return (
		<div className="page-not-found">
			{!isTitle ?
				<>
					<svg width="120" height="120" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path d="M57,0H3C1.346,0,0,1.346,0,3v56c0,0.552,0.448,1,1,1h58c0.552,0,1-0.448,1-1V3
							C60,1.346,58.654,0,57,0z M3,2h54c0.551,0,1,0.449,1,1v5H2V3C2,2.449,2.449,2,3,2z M19,53v5h-8v-5H19z M21,53h8v5h-8V53z M31,53h8
							v5h-8V53z M41,53h8v5h-8V53z M2,51V17h56v34H2z M2,15v-5h56v5H2z M2,53h7v5H2V53z M51,58v-5h7v5H51z"/>
							<rect x="5" y="4" width="4" height="2"/>
							<rect x="12" y="4" width="4" height="2"/>
							<path d="M16,24h-2v13H8V24H6v14c0,0.552,0.448,1,1,1h7v6h2v-6h3v-2h-3V24z"/>
							<path d="M54,37h-3V24h-2v13h-6V24h-2v14c0,0.552,0.448,1,1,1h7v6h2v-6h3V37z"/>
							<path d="M30,24c-3.86,0-7,3.14-7,7v7c0,3.86,3.14,7,7,7s7-3.14,7-7v-7C37,27.14,33.86,24,30,24z M35,38
							c0,2.757-2.243,5-5,5s-5-2.243-5-5v-7c0-2.757,2.243-5,5-5s5,2.243,5,5V38z"/>
						</g>
					</svg>
					<div className="txt">Not Found</div>
				</>
				: <>
					<svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297">
						<path d="M294.908,142.225c-0.566-0.756-14.168-18.72-38.881-36.693c-10.007-7.277-20.418-13.504-31.116-18.652l47.458-47.458
							c4.084-4.084,4.084-10.706,0-14.79c-4.085-4.083-10.705-4.083-14.79,0L203.922,78.29c-18.06-6.122-36.7-9.269-55.42-9.269
							c-37.501,0-74.683,12.625-107.526,36.51C16.262,123.506,2.658,141.47,2.092,142.225c-2.789,3.718-2.789,8.831,0,12.549
							c0.566,0.756,14.17,18.72,38.884,36.694c10.006,7.277,20.418,13.503,31.115,18.651l-47.458,47.458
							c-4.084,4.084-4.084,10.706,0,14.79c2.043,2.042,4.719,3.063,7.394,3.063c2.678,0,5.354-1.021,7.396-3.063l53.658-53.658
							c18.062,6.122,36.701,9.268,55.421,9.268c37.502,0,74.684-12.625,107.525-36.511c24.713-17.974,38.315-35.938,38.881-36.693
							C297.697,151.057,297.697,145.943,294.908,142.225z M207.065,148.5c0,32.292-26.271,58.564-58.563,58.564
							c-12.376,0-23.859-3.87-33.328-10.446l23.981-23.98c2.899,1.123,6.05,1.746,9.347,1.746c14.296,0,25.883-11.587,25.883-25.883
							c0-3.298-0.623-6.447-1.746-9.348l23.98-23.98C203.196,124.641,207.065,136.123,207.065,148.5z M89.939,148.5
							c0-32.292,26.271-58.563,58.564-58.563c12.376,0,23.859,3.868,33.326,10.446l-23.98,23.98c-2.9-1.123-6.049-1.746-9.346-1.746
							c-14.296,0-25.883,11.587-25.883,25.883c0,3.297,0.623,6.446,1.746,9.346l-23.98,23.98C93.808,172.358,89.939,160.876,89.939,148.5z
							 M24.153,148.5c5.687-6.283,15.785-16.427,29.681-26.457c9.118-6.581,18.458-12.157,27.996-16.725
							c-8.088,12.443-12.807,27.268-12.807,43.182s4.719,30.738,12.807,43.182c-9.538-4.567-18.878-10.144-27.996-16.725
							C39.937,164.925,29.836,154.779,24.153,148.5z M243.167,174.957c-9.115,6.581-18.456,12.156-27.991,16.724
							c8.086-12.442,12.805-27.268,12.805-43.181s-4.719-30.738-12.805-43.181c9.535,4.567,18.876,10.143,27.991,16.724
							c13.897,10.032,23.998,20.178,29.681,26.457C267.162,154.783,257.063,164.927,243.167,174.957z"/>
					</svg>
					<div>Please enter right url with sub-domain <br/>OR to make visible your base in settings</div>
				</>
			}
		</div>
	);
};

export default PageError;