import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PUBLIC_ARTICLE, LIKE_DISLIKE_ARTICLE } from './query';
import Editor from './Editor';
import Loading from './Loading';
import { Link, useParams } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import Categories from './Categories';
import PageError from './PageError';

const Article = ({ kbId, stylesKb }) => {
	const { categorySlug, articleSlug } = useParams();
	const { data, loading } = useQuery(GET_PUBLIC_ARTICLE, { variables: { slug: articleSlug, kbId }, fetchPolicy: 'no-cache' });
	const [ getLikeOrDislikeArticle ] = useMutation(LIKE_DISLIKE_ARTICLE);

	const [article, setArticle] = useState(null);
	const [sectionSlug, setSectionSlug] = useState('');
	const [categories, setCategories] = useState([]);
	const [isShowRating, setIsShowRating] = useState(true);
	const [crumbs, setCrumbs] = useState([{ title: 'Home', url: '/' }]);

	useEffect(() => {
		if (data) {
			const article = data.data;
			if (article) {
				setCategories(article.categories[0].section.publicCategories);
				setArticle(article);
				setSectionSlug(article.categories[0].section.slug);
				setIsShowRating(!getCookie(article.id));
				setCrumbs([
					{
						title: 'Home',
						url: '/',
					},
					{
						title: article.categories[0].section.title,
						url: `/section/${article.categories[0].section.slug}/category/${article.categories[0].slug}`,
					},
					{
						title: article.categories[0].title,
						url: `/section/${article.categories[0].section.slug}/category/${article.categories[0].slug}`,
					},
				]);
			}
		}
	}, [data]);

	const likeOrDislikeRating = (status) => {
		getLikeOrDislikeArticle({ variables: { action: status, articleId: article.id }}).then(() => {
			setIsShowRating(false);
			setCookie(`${article.id}`, article.id, 30);
		});
	};

	const setCookie = (name, value, days) => {
		let expires = '';

		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '')  + expires + '; path=/';
	};

	const getCookie = (name) => {
		const nameCompare = name + '=';
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1, cookie.length);
			}
			if (cookie.indexOf(nameCompare) === 0) {
				return cookie.substring(nameCompare.length, cookie.length);
			}
		}
		return null;
	};

	return (
		!loading ?
			article ? <div className="content">
				<div className="section">
					<BreadCrumbs crumbs={crumbs} stylesKb={stylesKb} />
					<div className="section-main">
						<div className="category-aside">
							<Categories
								categories={categories}
								stylesKb={stylesKb}
								sectionSlugFirst={sectionSlug}
								categorySlugFirst={categorySlug}
							/>
						</div>
						<div className="content-aside">
							{data ?
								<div>
									<div className="title">{article.title}</div>
									<Editor body={article.body}/>
									{Boolean(article.tags.length) &&
										<div className="tags-wrap">
											Tags:
											{article.tags.map(tag =>
												<Link to={`/tag/${tag.tag}`} key={tag.id} className="link">
													{tag.tag}
												</Link>
											)}
										</div>
									}
									{stylesKb.allowRatings &&
										<div className="rating-wrap">
											{isShowRating ?
												<>
													<div className="title-rating">Did you find this article helpful?</div>
													<div className="icons-rating">
														<div onClick={() => likeOrDislikeRating('likes')}>
															<svg className="svg-icon like" width="24" height="24"
															     viewBox="0 0 16 16"
															     xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M13.7441 5.3131H10.4501V3.05397C10.4501 1.97011 10.1269 1.18333 9.48704 0.716491C8.48153 -0.0213175 7.11365 0.393292 7.05489 0.41288C6.8231 0.484702 6.66313 0.703433 6.66313 0.948281V3.67425C6.66313 5.6918 4.28974 6.3937 4.18853 6.41982C4.182 6.42308 4.17547 6.42308 4.17221 6.42635L3.90124 6.51123C3.59437 6.22067 3.18302 6.04438 2.7325 6.04438H1.70414C0.763926 6.04112 0 6.80504 0 7.74526V13.6086C0 14.5488 0.763926 15.3127 1.70414 15.3127H2.7325C3.1667 15.3127 3.56499 15.1495 3.86533 14.8785C4.29627 15.391 4.94266 15.7208 5.66415 15.7208H12.3371C13.8845 15.7208 14.9129 14.872 15.0924 13.4518L15.9216 8.24475L15.9674 7.96399C15.9869 7.83341 16 7.69955 16 7.5657C15.9967 6.32514 14.9847 5.3131 13.7441 5.3131ZM3.31687 13.6118C3.31687 13.935 3.0557 14.1962 2.7325 14.1962H1.70414C1.38094 14.1962 1.11977 13.935 1.11977 13.6118V7.74526C1.11977 7.42206 1.38094 7.16089 1.70414 7.16089H2.7325C3.0557 7.16089 3.31687 7.42206 3.31687 7.74526V13.6118ZM14.8606 7.79097L13.9857 13.2886C13.9857 13.2951 13.9825 13.3017 13.9825 13.3082C13.9433 13.6281 13.8257 14.6043 12.3371 14.6043H5.66415C4.98837 14.6043 4.43665 14.0525 4.43665 13.3768V7.74526C4.43665 7.67017 4.43012 7.59509 4.42032 7.52L4.50194 7.49388C4.74026 7.42533 7.7829 6.49164 7.7829 3.67099V1.40859C8.1061 1.37595 8.5305 1.3988 8.82759 1.6208C9.16058 1.86891 9.33034 2.34881 9.33034 3.05397V5.87462C9.33034 6.18476 9.58172 6.43614 9.89186 6.43614H13.7441C14.3709 6.43614 14.877 6.94542 14.877 7.56897C14.877 7.64079 14.8704 7.71588 14.8606 7.79097Z"/>
															</svg>
														</div>
														<div onClick={() => likeOrDislikeRating('dislikes')}>
															<svg className="svg-icon dislike" width="24" height="24"
															     viewBox="0 0 16 16"
															     xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M13.7441 5.3131H10.4501V3.05397C10.4501 1.97011 10.1269 1.18333 9.48704 0.716491C8.48153 -0.0213175 7.11365 0.393292 7.05489 0.41288C6.8231 0.484702 6.66313 0.703433 6.66313 0.948281V3.67425C6.66313 5.6918 4.28974 6.3937 4.18853 6.41982C4.182 6.42308 4.17547 6.42308 4.17221 6.42635L3.90124 6.51123C3.59437 6.22067 3.18302 6.04438 2.7325 6.04438H1.70414C0.763926 6.04112 0 6.80504 0 7.74526V13.6086C0 14.5488 0.763926 15.3127 1.70414 15.3127H2.7325C3.1667 15.3127 3.56499 15.1495 3.86533 14.8785C4.29627 15.391 4.94266 15.7208 5.66415 15.7208H12.3371C13.8845 15.7208 14.9129 14.872 15.0924 13.4518L15.9216 8.24475L15.9674 7.96399C15.9869 7.83341 16 7.69955 16 7.5657C15.9967 6.32514 14.9847 5.3131 13.7441 5.3131ZM3.31687 13.6118C3.31687 13.935 3.0557 14.1962 2.7325 14.1962H1.70414C1.38094 14.1962 1.11977 13.935 1.11977 13.6118V7.74526C1.11977 7.42206 1.38094 7.16089 1.70414 7.16089H2.7325C3.0557 7.16089 3.31687 7.42206 3.31687 7.74526V13.6118ZM14.8606 7.79097L13.9857 13.2886C13.9857 13.2951 13.9825 13.3017 13.9825 13.3082C13.9433 13.6281 13.8257 14.6043 12.3371 14.6043H5.66415C4.98837 14.6043 4.43665 14.0525 4.43665 13.3768V7.74526C4.43665 7.67017 4.43012 7.59509 4.42032 7.52L4.50194 7.49388C4.74026 7.42533 7.7829 6.49164 7.7829 3.67099V1.40859C8.1061 1.37595 8.5305 1.3988 8.82759 1.6208C9.16058 1.86891 9.33034 2.34881 9.33034 3.05397V5.87462C9.33034 6.18476 9.58172 6.43614 9.89186 6.43614H13.7441C14.3709 6.43614 14.877 6.94542 14.877 7.56897C14.877 7.64079 14.8704 7.71588 14.8606 7.79097Z"/>
															</svg>
														</div>
													</div>
													{stylesKb.showCount &&
														<div>
															{article.likes} out of {article.dislikes} people found this
															article helpful so far
														</div>
													}
												</>	:
												<div className="complete-rating">
													<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
														<path d="M14.448,9.111 L10.587,12.981 L9.102,11.496 C8.88204911,11.2391615 8.53669344,11.1272863 8.20792292,11.2063709 C7.87915241,11.2854555 7.62245551,11.5421524 7.54337088,11.8709229 C7.46428625,12.1996934 7.57616146,12.5450491 7.833,12.765 L9.948,14.889 C10.2990337,15.2379512 10.8659663,15.2379512 11.217,14.889 L15.717,10.389 C15.9452927,10.162315 16.0353089,9.83105668 15.9531402,9.52000687 C15.8709716,9.20895707 15.6291016,8.96537164 15.3186402,8.88100687 C15.0081789,8.79664209 14.6762927,8.88431498 14.448,9.111 L14.448,9.111 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,19.2 C8.0235498,19.2 4.8,15.9764502 4.8,12 C4.8,8.0235498 8.0235498,4.8 12,4.8 C15.9764502,4.8 19.2,8.0235498 19.2,12 C19.2,15.9764502 15.9764502,19.2 12,19.2 L12,19.2 Z"/>
													</svg>
													<div>Thank you for your feedback!</div>
												</div>
											}
										</div>
									}
								</div>
								: <Loading />
							}
						</div>
					</div>
				</div>
			</div> : <PageError />
		: <Loading />
	);
};

export default Article;
